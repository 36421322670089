<template>
  <div class="volucap">
    <meta name="googlebot" content="noindex">
    <meta name="robots" content="noindex">

    <div class="volucap__loading-blocker"></div>

    <div class="site-wrapper__content no-padding-sm">
      <div class="volucap__header container page-component loading-hidden">
        <h1>Web-Referenzen</h1>
        <h3>Bewerbung bei Volucap</h3>
        <p>Sehr geehrtes Volucap Team,</p>
        <p>
          Vielen Dank für das Telefonat und die Möglichkeit für ein Bewerbungsgespr&auml;ch.<br />
          Wie abgemacht schicke ich Ihnen noch vorab eine kleine Zusammenstellung meiner Web-Referenzen.
        </p>
      </div>

      <div class="volucap__projects loading-hidden delay-2">
        <projects page-index="2" :projects="projectsData"/>
      </div>

      <div class="container-sm page-component">
        <p>
          Meine Kernkompetenzen in der Web-Entwicklung liegen - abgesehen von den standard Sprachen <strong>HTML / CSS / JavaScript</strong> - vor allem
          auf modernen Front-End Javascript Frameworken wie <strong>Vue.JS oder Three.JS</strong>.
        </p>
        <p>
          Die Seite auf der sie sich im Moment befinden, um ein konkretes Beispiel zu nennen, habe ich mit Vue.Js und Three.JS umgesetzt. Vue.js regelt hierbei die grundlegende Darstellung
          sowie die Datenverarbeitung. Three.JS ist für die komplexeren Animationen, wie z.B. die Image-Transition bei den Projekt Bildern, zuständig.
        </p>
        <p>
          Den Code der Firmen-Projekte kann ich natürlich nicht öffentlich machen, der Code einiger meiner privaten Projekte ist bei Interesse allerdings auf 
          <a href="https://github.com/aalolexx" target="_blank">Github</a> einsehbar.
        </p>
        <p>
          Three.JS ist zusätzlich <strong>die</strong> Programmiersprache für 3D im Web - was für Volucap ganz interessant sein könnte.
          Diesbezüglich habe ich auch schon einige private Experimente umgesetzt, unter Anderem:
        </p>
        <br>
        <div class="services__container">
          <div class="services__item">
            <div class="card__content">
              <img src="/images/three-js-car.png" width="100%" />
              <p>Experiment für realistisches 3D realtime in-browser rendering</p>
              <a class="btn" target="_blank" href="https://alexander-ehrenhoefer.com/playground/three-js-cinematic-test/">live testen</a>
            </div>
          </div>
          <div class="services__item">
            <div class="card__content">
              <img src="/images/three-js-room.png" width="100%" />
              <p>Experiment für interactive 3D Programmierung in Websiten</p>
              <a class="btn" target="_blank" href="https://alexander-ehrenhoefer.com/dev/">live testen</a>
            </div>
          </div>
        </div>
      </div>
      <div class="page-component container-sm">
        <p>
          Zu guter Letzt möchte ich noch ein App Projekt, dass ich als Zeitvertreib im Corona-Lockdown umgesetzt habe vorstellen. Auch die App wurde hauptsächlich mit den zuvor
          genannten Programmiersprachen programmiert.
        </p>
        <p class="page-component">
          Die App namens Movie Swiper ermöglichte es durch einen Tinder-ähnlichen Swiping Mechanismus innerhalb von Freundesgruppen Filme zu finden, die alle gerne mal schauen würden. Leider musste ich das Projekt einstellen, da die Wartungsarbeiten bei der unerwartet hohen Anzahl Nutzern (Knapp 40'000)  zu Zeit- und Kostenintensiv wurden.
          Deshalb ist die App nicht mehr im Google Play Store einsehbar. So sah die App allerdings aus:
        </p>
        <img width="100%" src="/images/movieswiper/header.png" />
      </div>
      <div class="container page-component">
        <div class="volucap__container-5">
          <div><img src="/images/movieswiper/s1.jpg" /></div>
          <div><img src="/images/movieswiper/s2.jpg" /></div>
          <div><img src="/images/movieswiper/s3.jpg" /></div>
          <div><img src="/images/movieswiper/s4.jpg" /></div>
          <div><img src="/images/movieswiper/s5.jpg" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Rellax from 'rellax'
import Projects from '@/components/Projects.vue'
import { EventBus } from '@/js/EventBus.js'

export default {
  name: 'Home',
  components: { Projects },

  mounted () {
    //new Rellax('.rellax')
    EventBus.$on('projects-loaded', () => this.endLoading())
  },

  data () {
    return {
      isLoading: true,
      projectsData: [
        {
          image: '/images/projects/SRF-Medienportal-logo.svg',
          contentHTML: `<h1>SRF Medienportal</h1>
          <p class="projects__slider-meta">worked as <span>Full-Stack Developer</span> for <span>clavis IT AG</span></p>
          <p>Das Medienportal des schweizerischen Landessender SRF ist die Anlaufstelle für Medien bezüglich neuen TV-Sendungen, Zuschauerzahlen, Unternehmensnews, Chroniken und vielem mehr.</p>
          <a class="btn" target="_blank" href="https://medien.srf.ch/">zu medien.srf.ch</a>
          `
        },
        {
          image: '/images/projects/Keystone-SDA-logo.png',
          contentHTML: `<h1>Keystone-SDA</h1>
          <p class="projects__slider-meta">worked as <span>Front-End Developer</span> for <span>clavis IT AG</span></p>
          <p>Keystone-SDA gehört zu den größten Depeschenagenturen der Schweiz. Die von der clavis IT entwickelte Unternehmensseite stellt unter anderem die Dienste, Formate und News der Agentur vor. </p>
          <a class="btn" target="_blank" href="https://www.keystone-sda.ch/">zu keystone-sda.ch</a>
          `
        },
        {
          image: '/images/projects/Kriens-logo.png',
          contentHTML: `<h1>Kriens Tourismus</h1>
          <p class="projects__slider-meta">worked as <span>Project Manager</span> and <span>Lead-Developer</span></p>
          <p>Kriens Tourismus ist die offizielle Tourismusseite der an Luzern grenzenden Stadt Kriens und bietet Touristen und Besuchern einen Überblick über Sehenswürdigkeiten, Restaurants, Einkaufsmöglichkeiten und Aktivitäten der Stadt.</p>
          <a class="btn" target="_blank" href="https://kriens-tourismus.ch/#/home">zu kriens-tourismus.ch</a>
        `
        },
        {
          image: '/images/projects/AE-Photography-logo.svg',
          contentHTML: `<h1>AE Photography</h1>
          <p class="projects__slider-meta">private project</p> 
          <p>Die Alexander Ehrenhöfer Photography Seite ist ein privates Projekt und dient einerseits als Portfolio für Fotos, vor allem aber als eigene Übung und Spielwiese für meine Webprogrammierung.</p>
          <a class="btn" target="_blank" href="https://www.photography.alexander-ehrenhoefer.com/#/">zur Seite</a>
          `
        }
      ],
    }
  },

  methods: {
    endLoading () {
      document.body.classList.remove('loading')
    }
  }

}
</script>
