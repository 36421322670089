<template>
  <div class="projects page-component">
    <section-title v-if="showSectionTitle" :messages="projectsTitle" />
    <div class="js-nav-indicator" v-view="(e) => updateNavIndex(e, pageIndex)" :data-index="pageIndex"></div>
    <div class="projects__slider-wrapper container">
      <div class="projects__slider-text-container">
        <template v-for="(project, index) in projects">
          <div :key="project.image" class="projects__slider-text-item" v-view="(e) => viewHandlerText(e, index)">
            <img class="projects__slider-logo" :src="project.image"/>
            <div v-html="project.contentHTML"></div>
          </div>
          <div :key="project.image + 'x'" class="projects__js-slider-indicator" :data-index="index" v-view="(e) => viewHandlerScrollIndicator(e, index)"></div>
        </template>
        <div class="projects__js-slider-indicator" data-index="3" v-view="(e) => viewHandlerScrollIndicator(e, 3)"></div>
      </div>
      <div class="projects__slider-image-container">
        <div class="projects__slider-image" id="projects__js-three-container">
          <projects-scene :tex1="currentImage" :tex2="nextImage" :transitionProgress="transitionProgress"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/elements/SectionTitle.vue'
import ProjectsScene from '@/elements/ProjectsScene.vue'
import { EventBus } from '@/js/EventBus.js'

export default {
  components: { SectionTitle, ProjectsScene },
  name: 'Projects',
  props: ['pageIndex', 'projects', 'showSectionTitle'],
  data () {
    return {
      projectsTitle: [
        {
          side: 'right',
          content: 'Interesting...'
        },
        {
          side: 'right',
          content: 'Do you have any professional references?'
        },
        {
          side: 'left',
          content: 'Yeah!'
        },
        {
          side: 'left',
          content: 'Here are some of the larger projects I worked on'
        }
      ],
      scrollEvents: {
        IS_TRANSITION: 0,
        TRANSITION_DONE: 1,
        IMAGE_CHANGE: 2
      },
      currentProjectView: {
        index: 0,
        percentInView: 0,
        percentTop: 0,
        currentImage: 0,
        nextImage: 1,
      },
      currentScrollIndicator: {
        index: 0,
        currentElement: null,
        scrollProgress: 0,
        percentInView: 0,
      },
      currentImage: 0,
      nextImage: 1,
      transitionProgress: 0,
      currentScrollEvent: 1,
      threeJsContainerElement: null
    }
  },
  created () {},
  mounted () {
    this.threeJsContainerElement = document.getElementById('projects__js-three-container')
    this.currentScrollIndicator.currentElement = document.querySelector('.projects__js-slider-indicator[data-index="0"')
  },
  methods: {
    viewHandlerText (e, index) {
      if (index == this.currentProjectView.index) {
        this.currentProjectView.percentInView = e.percentInView
        this.currentProjectView.percentTop = e.percentTop

        let tempNextImage
        if (e.percentTop > 0.5) {
          tempNextImage = index - 1
        } else {
          tempNextImage = index + 1
        }

        if (tempNextImage != this.currentProjectView.currentImage) {
          this.currentProjectView.currentImage = index
          this.currentProjectView.nextImage = tempNextImage
          if (this.currentScrollEvent != this.scrollEvents.IS_TRANSITION) {
            this.currentImage = index
            this.nextImage = tempNextImage
            // TODO AEH at this point the image flackers
          }
        }
      } else if (e.percentInView > this.currentProjectView.percentInView) {
        this.currentProjectView.index = index
      }
    },
    viewHandlerScrollIndicator (e, index) {
      if (index == this.currentScrollIndicator.index) {
        this.currentScrollIndicator.percentInView = e.percentInView
      } else if (e.percentInView > this.currentScrollIndicator.percentInView) {
        this.currentScrollIndicator.index = index
        this.currentScrollIndicator.currentElement = document.querySelector('.projects__js-slider-indicator[data-index="' + index +'"')
      }

      let threeJsContainerHeight = this.threeJsContainerElement.getBoundingClientRect().height
      let transitionAreaHeight = threeJsContainerHeight * 0.4
      // Ofset relative to the total wrapper height
      let offsetTotalTop = (this.currentScrollIndicator.currentElement.getBoundingClientRect().top - this.threeJsContainerElement.getBoundingClientRect().top)
      // Actual offset in the transition Area (40% of the total wrapper height)
      let offsetTransitionAreaTop = offsetTotalTop - 0.5 * (threeJsContainerHeight - transitionAreaHeight)
      let transitionProgress = 100 / transitionAreaHeight * offsetTransitionAreaTop
      transitionProgress = 100 - transitionProgress // Revert
      if (transitionProgress > 96) {
        transitionProgress = 100
        this.updatePos(this.scrollEvents.TRANSITION_DONE, this.currentProjectView.nextImage)
      }
      if (transitionProgress < 4) {
        transitionProgress = 0
        this.updatePos(this.scrollEvents.TRANSITION_DONE, this.currentProjectView.nextImage)
      }
      if (transitionProgress > 5 && transitionProgress < 95) {
        this.updatePos(this.scrollEvents.IS_TRANSITION, transitionProgress)
      }
    },
    updatePos (event, data1) {
      if (event != this.currentScrollEvent) {
        this.currentScrollEvent = event

        if (event == this.scrollEvents.TRANSITION_DONE) {
          this.transitionProgress = 0
          this.nextImage = this.currentImage
          this.currentImage = data1
        }
      }
      if (event == this.scrollEvents.IS_TRANSITION) {
        // Revert transition progress if direction is upwords
        if (this.currentImage > this.nextImage) {
          this.transitionProgress = 100 - data1
        } else {
          this.transitionProgress = data1
        }
      }
    },
    updateNavIndex (e, index) {
      EventBus.$emit('update-nav-index', index)
    }
  }
}
</script>
