<template>
  <div class="side-nav">
    <div v-if="navContent" class="side-nav__inner">
      <div class="side-nav__dots">
        <div class="dot"
            v-for="item in navContent"
            :key="item.pageIndex"
            @click="navigateToIndex(item.pageIndex)" >
        </div>
        <div class="dot dot--indicator"></div>
      </div>
      <div class="side-nav__text-wrapper">
        <div class="side-nav__text">
          <span>{{ currentNavTitle }}.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/js/EventBus.js'
import SmoothScroll from '@/js/SmoothScroll.js'

export default {
  name: 'SideNav',
  props: [ 'navContent' ],

  created () {
    EventBus.$on('update-nav-index', this.updateNavIndex)
    EventBus.$on('go-to-nav-index', this.navigateToIndex)
  },

  data () {
    return {
      currentNavTitle: this.navContent[0].title
    }
  },

  methods: {
    updateNavIndex (index) {
      document.documentElement.style.setProperty('--nav-index', index)
      this.currentNavTitle = this.getNavContentByIndex(index)
                             ? this.getNavContentByIndex(index).title
                             : null
    },

    navigateToIndex (index) {
      SmoothScroll.scrollToElementByQuery(`.js-nav-indicator[data-index="${index}"]`)
    },

    getNavContentByIndex (index) {
      return this.navContent.find((item) => item.pageIndex == index)
    }
  }
}
</script>