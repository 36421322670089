<template>
  <div class="services page-component">
    <section-title :messages="sloganTitle"/>
    <div class="js-nav-indicator" v-view="(e) => updateNavIndex(e, pageIndex)" :data-index="pageIndex"></div>
    <div class="container">
      <div class="services__container">
        <div class="services__item">
          <div class="card__content">
            <h3>Working student: Volucap at Studio Babelsberg</h3>
            <p>As a working student at Volucap, based at Filmstudio Babelsberg, I was instrumental in working on the Voluverse, a groundbreaking AR/VR showcase metaverse project developed with Unity.
              In this exciting role, I was able to contribute my knowledge and passion for immersive technologies and help bring the Voluverse to life. More about the project here: <a href="//volucap.com/voluverse/" target="_blank">Volucap Voluverse</a></p>
          </div>
        </div>
        <div class="services__item">
          <div class="card__content">
            <h3>Working student: Research group Creative Media HTW Berlin</h3>
            <p>At the Creative Media research group at the FKI of the HTW Berlin, I was able to participate in the "Complex Space Adventure" project and develop an educational game with Unity, which is intended to help international
              students, especially in the field of electrical engineering, to understand complex numbers.</p>
          </div>
        </div>
        <div class="services__item">
          <div class="card__content">
            <h3>Software Coach: The Buez</h3>
            <p>As part of my community service, I managed software and web projects for the association "THE BUEZ", which helps jobseekers to gain a foothold in the primary labor market, and supervised the project participants. The "Kriens-Tourismus" project mentioned in the references was created during this time.</p>
          </div>
        </div>
        <div class="services__item">
          <div class="card__content">
            <h3>Web-Developer: clavis IT AG</h3>
            <p>As a web developer at clavis IT AG, I was responsible for the implementation and maintenance of web projects. This included the implementation of parts in the backend with Java as well as development in the frontend with JavaScript or Vue.js, HTML and CSS. My tasks also included the maintenance of the portals and the associated server configurations.</p>
          </div>
        </div>
        <!--<div class="services__item">
          <div class="card__content">
            <h3>Web Entwicklung</h3>
            <p>Meine Kernkompetenz liegt in der Webentwicklung. Ein Webauftritt ist heute für jedes Unternehmen Standard, wichtig ist deshalb, dass sie mit Ihrem aus der Masse hervorstechen. Dabei helfe ich Ihnen.</p>
          </div>
        </div>
        <div class="services__item">
          <div class="card__content">
            <h3>Web Design</h3>
            <p>Für den perfekten Webauftritt genügt natürlich nicht nur die einfache Entwicklung einer Website. Das Design ist hier Match-Entscheidend.</p>
          </div>
        </div>
        <div class="services__item">
          <div class="card__content">
            <h3>Interaktives 3D Web</h3>
            <p>Für den Webauftritt der ganz besonderen Art biete ich auch interaktive 3d Websiten an. Geeignete Anwendugsfälle wären beispielsweise Geländekarten oder Produktvorstellungen.</p>
          </div>
        </div>
        <div class="services__item">
          <div class="card__content">
            <h3>Grafikdesign</h3>
            <p>Vielleicht doch nicht nur eine neue Website, sondern ein komplett neues Branding? Gerne helfe ich Ihnen bei Ihrer ci/cd Entwicklung.</p>
          </div>
        </div>
        <div class="services__item">
          <div class="card__content">
            <h3>Fotografie</h3>
            <p>Fotografie bildet den gewünschen Realitätsbezug auf Ihr Unternehmen und ist ein essentieller Bestandteil auch in der digitalen Welt. Als leidenschaftlicher Fotograf fertige ich gerne das gewünschte Bildmaterial an.</p>
          </div>
        </div>
        <div class="services__item">
          <div class="card__content">
            <h3>App Entwicklung</h3>
            <p>Je nach Unternehmen ist auch die Realisierung einer App sinnvoll. Beispielsweise für Reservationen, Bestellungen etc.</p>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/elements/SectionTitle.vue'
import { EventBus } from '@/js/EventBus.js'

export default {
  components: { SectionTitle },
  name: 'Experience',
  props: ['pageIndex'],
  data () {
    return {
      sloganTitle: [
        {
          side: 'right',
          content: 'What is your work experience?'
        },
        {
          side: 'left',
          content: 'These are the companies I worked for'
        },
      ],
    }
  },

  methods: {
    updateNavIndex (e, index) {
      EventBus.$emit('update-nav-index', index)
    }
  }
}
</script>
