<template>
  <div class="projects-scene">
    <div id="three-projects-scene-canvas"></div>

    <!-- SHADERS -->
    <script id="vertexShader" type="x-shader/x-vertex">
          precision mediump float;
          precision mediump int;
          attribute vec4 color;
          varying vec3 vPosition;
          varying vec4 vColor;
          varying vec2 vUv;
          void main()	{
            vUv = uv;
            vPosition = position;
            vColor = color;
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1);
          }
    </script>
    <script id="fragmentShader" type="x-shader/x-vertex">
        uniform float time;
        uniform float progress;
        uniform float intensity;
        uniform float width;
        uniform float scaleX;
        uniform float scaleY;
        uniform float transition;
        uniform float radius;
        uniform float swipe;
        uniform sampler2D texture1;
        uniform sampler2D texture2;
        uniform sampler2D displacement;
        uniform vec4 resolution;
        varying vec2 vUv;
        mat2 getRotM(float angle) {
            float s = sin(angle);
            float c = cos(angle);
            return mat2(c, -s, s, c);
        }
        const float PI = 3.1415;
        const float angle1 = PI * 2.71;
        const float angle2 = PI * 1.76;
        void main()	{
          vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
          vec4 disp = texture2D(displacement, newUV);
          vec2 dispVec = vec2(disp.r, disp.g);
          vec2 distortedPosition1 = newUV + getRotM(angle1) * dispVec * intensity * progress;
          vec4 t1 = texture2D(texture1, distortedPosition1);
          vec2 distortedPosition2 = newUV + getRotM(angle2) * dispVec * intensity * (1.0 - progress);
          vec4 t2 = texture2D(texture2, distortedPosition2);
          gl_FragColor = mix(t1, t2, progress);
        }
    </script>
  </div>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { EventBus } from '@/js/EventBus.js'

export default {
  name: 'ProjectsScene',
  data () {
    return {
      sceneCanvas: null,
      scene: null,
      camera: null,
      renderer: null,
      controls: null,
      material: null,
      ctx: null,
      imagesPaths: [
        '/images/projects/SRF-Medienportal-Showcase.jpg',
        '/images/projects/Keystone-SDA-Showcase.jpg',
        '/images/projects/Kriens-Tourismus-Showcase.jpg',
        '/images/projects/AE-Photography-Showcase.jpg',
      ],
      preparedTextures: [],
      aspectRatio: 5/3,
      imageSize: null
    }
  },
  props: {
    tex1: {default: 0},
    tex2: {default: 1},
    transitionProgress: {default: 0}
  },
  watch: {
    tex1 (newVal,) {
      this.material.uniforms.texture1 = { type: "t", value: this.preparedTextures[newVal] }
      this.animateThreeJs()
    },
    tex2 (newVal) {
      this.material.uniforms.texture2 = { type: "t", value: this.preparedTextures[newVal] }
      this.animateThreeJs()
    },
    transitionProgress (newVal) {
      this.material.uniforms.progress = { value: newVal/100 }
      this.animateThreeJs()
    }
  },
  mounted () {
    // Set canvas size for correct aspect ration
    let canvasWrapper = document.getElementById('three-projects-scene-canvas')
    canvasWrapper.style.height = canvasWrapper.getBoundingClientRect().width * this.aspectRatio + 'px'
    this.imageSize = canvasWrapper.getBoundingClientRect().height

    /* **************
       BASIC SETUP
    ************** */

    this.sceneCanvas = document.getElementById('three-projects-scene-canvas')
    this.scene = new THREE.Scene()
    this.camera = new THREE.PerspectiveCamera(
      45,
      this.sceneCanvas.getBoundingClientRect().width / this.sceneCanvas.getBoundingClientRect().height,
      0.1,
      2000
    )
    this.camera.position.set(0, 0, 12.5)
    
    this.renderer = new THREE.WebGLRenderer({
       alpha: true
    })
    this.renderer.outputEncoding = THREE.sRGBEncoding

    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.enablePan = false
    this.controls.enableZoom = false
    this.controls.addEventListener('change', this.animateThreeJs )

    this.renderer.setSize(this.sceneCanvas.offsetWidth, this.sceneCanvas.offsetHeight)
    this.renderer.setClearColor(0xffffff, 0)
    this.renderer.shadowMap.enabled = true
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
    this.renderer.shadowMapSoft = true
    this.renderer.shadowMap.autoUpdate = false
    this.renderer.shadowMap.needsUpdate = true

    this.renderer.gammaFactor = 2.2
    this.renderer.gammaOutput = true

    this.sceneCanvas.append(this.renderer.domElement)

    let canvas = document.createElement("canvas")
		canvas.width = this.imageSize
		canvas.height = this.imageSize
    this.ctx = canvas.getContext("2d")

    this.material = new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 1.0 },
        progress: { value: 0.0 },
        border: { type: "f", value: 0 },
        intensity: {value: 0.5, type:'f', min:0, max:3},
        scaleX: { type: "f", value: 0 },
        scaleY: { type: "f", value: 0 },
        transition: { type: "f", value: 40 },
        swipe: { type: "f", value: 0 },
        width:{ type: "f", value: 0 },
        radius: { type: "f", value: 0 },
        displacement: { type: "f", value: new THREE.TextureLoader().load('https://raw.githubusercontent.com/akella/webGLImageTransitions/master/img/disp1.jpg') }, // TODO AEH
        resolution: { type: "v4", value: new THREE.Vector4() },
      },
      vertexShader: document.getElementById("vertexShader").textContent,
      fragmentShader: document.getElementById("fragmentShader").textContent,
    })

    this.loadImages().then((images) => {
      EventBus.$emit('projects-loaded')
      this.preparedTextures = images
      this.initializeShader()
    })

    this.animateThreeJs()

    window.addEventListener("resize", () => {
      this.resizeCanvas()
    })
  },
  methods: {
    animateThreeJs () {
      this.material.uniforms.time.value += 0.1
      this.renderer.render(this.scene, this.camera)
      this.renderer.shadowMap.needsUpdate = true
    },

    resizeCanvas () {
      let canvasWrapper = document.getElementById('three-projects-scene-canvas')
      canvasWrapper.style.height = canvasWrapper.getBoundingClientRect().width * this.aspectRatio + 'px'
      this.camera.aspect = this.sceneCanvas.getBoundingClientRect().width / this.sceneCanvas.getBoundingClientRect().height
      this.camera.updateProjectionMatrix()
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(this.sceneCanvas.offsetWidth, this.sceneCanvas.offsetHeight)
      this.imageSize = canvasWrapper.getBoundingClientRect().height
      this.animateThreeJs()
    },

    initializeShader () {
      let geometry = new THREE.PlaneGeometry(6, 10)
      this.material.uniforms.texture1 = { type: "t", value: this.preparedTextures[this.tex1] }
      this.material.uniforms.texture2 = { type: "t", value: this.preparedTextures[this.tex1] }
      let mesh = new THREE.Mesh(geometry, this.material)
      this.scene.add(mesh)
      this.animateThreeJs()
    },

    resizeImage (image) {
      let {width, height} = image
      let newWidth = this.imageSize/width
      let newHeight = this.imageSize/height
      let imageAspect = newHeight/newWidth
      let a1
      let a2
      if(newHeight/newWidth > imageAspect) {
        a1 = (newWidth/newHeight) * imageAspect;
        a2 = 1
      } else{
        a1 = 1
        a2 = (newHeight/newWidth) / imageAspect;
      }
      this.material.uniforms.resolution.value.x = newWidth
      this.material.uniforms.resolution.value.y = newHeight
      this.material.uniforms.resolution.value.z = a1
      this.material.uniforms.resolution.value.w = a2
      this.ctx.drawImage(image, 0, 0, width, height, 0,0, this.imageSize, this.imageSize)
      return this.ctx.getImageData(0,0,this.imageSize,this.imageSize)
    },

    makeThreeTexture (image) {
      let tex = new THREE.Texture(image)
      tex.needsUpdate = true
      return tex
    },

    loadImages() {
      let promises = []
      for (var i = 0; i < this.imagesPaths.length; i++) {
        promises.push(
          new Promise((resolve) => {
            let img = new Image()
            img.src = this.imagesPaths[i]
            img.crossOrigin = "anonymous"
            img.onload = image => {
              return resolve(image.target)
            }
            img.onerror = () => {
              console.log('failed to load image for projects')
            }
          }).then(this.resizeImage)
            .then(this.makeThreeTexture)
        )
      }
      return Promise.all(promises)
    }
  }

  // TODO AEH WINDOW SIZE WATCHER
}
</script>
