<template>
  <div id="app">
    <!--<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/cheat-sheet">c.s</router-link> |
      <router-link to="/login">login</router-link> | 
      <router-link to="/register">register</router-link> |
      <router-link to="/main">main</router-link>
    </div>-->
    <div class="site-wrapper">
      <router-view/>
    </div>
  </div>
</template>

<style lang="scss">
@import '/assets/style/custom.scss';
</style>

<script>
export default {
  name: 'App',
}
</script>