<template>
  <div class="scroll-indicator" v-if="!isClicked">
    <a class="mouse-scroll" @click="scrollDown"> 
      <span class="mouse">
        <span class="mouse-movement"> 
        </span>
      </span>
      <span class="mouse-message fadeIn">scroll</span> 
    </a>
  </div>
</template>

<script>
import { EventBus } from '@/js/EventBus.js'

export default {
  name: 'ScrollIndicator',

  data () {
    return {
      isClicked: false
    }
  },

  methods: {
    scrollDown () {
      EventBus.$emit('go-to-nav-index', 1)
      this.isClicked = true
    }
  }
}
</script>