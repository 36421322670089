import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import CheatSheet from '@/views/CheatSheet'
import Volucap from '@/views/Volucap'
import ShowcaseBachelorThesis from '@/views/ShowcaseBachelorThesis'
import CloudDownload from '@/views/CloudDownload'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/cheat-sheet',
    name: 'CheatSheet',
    component: CheatSheet
  },
  {
    path: '/volucap',
    name: 'Volucap',
    component: Volucap
  },
  {
    path: '/research/bachelor-thesis',
    name: 'ShowcaseBachelorThesis',
    component: ShowcaseBachelorThesis
  },
  {
    path: '/cloud/download/:filename',
    name: 'CloudDownload',
    component: CloudDownload
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }  
})

export default router
