<template>
  <div class="slogan page-component">
    <section-title :messages="sloganTitle"/>
    <div class="js-nav-indicator" v-view="(e) => updateNavIndex(e, pageIndex)" :data-index="pageIndex"></div>
    <div class="slogan__content">
      <div class="container-sm">
        <h2 class="xl">Hi, i'm Alex and I work in web development, data science, machine learning and computer vision.</h2>
      </div>
      <div class="slogan__content-backdrop">
        <!--<h1 class="xl rellax" data-rellax-speed="-4" data-rellax-percentage="0.5">TUB Master Student in Berlin</h1>-->
        <h1 class="xl">TUB Master Student in Berlin</h1>
        <!--<div class="container-sm slogan__content-backdrop-img-wrapper">
          <div class="slogan__content-backdrop-img-overlay">
            <img src="/images/alex.png" class="rellax" data-rellax-percentage="0.5"/>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/elements/SectionTitle.vue'
import { EventBus } from '@/js/EventBus.js'

export default {
  components: { SectionTitle },
  name: 'Slogan',
  props: ['pageIndex'],

  data () {
    return {
      sloganTitle: [
        {
          side: 'right',
          content: 'Who is it?'
        },
        {
          side: 'left',
          content: 'Hey there!'
        },
        {
          side: 'left',
          content: 'Let me introduce myself'
        },
      ],
    }
  },

  methods: {
    updateNavIndex (e, index) {
      EventBus.$emit('update-nav-index', index)
    }
  }
}
</script>
