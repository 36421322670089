<template>
  <div class="section-title page-component">
    <div class="section-title__message-container" v-if="messages">
      <div v-for="message in messages"
           :key="message.content"
           :class="'section-title__message-row ' + message.side"
      >
        <div :class="'section-title__message-content ' + message.side">{{ message.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    messages: {
      type: Array
    }
  }
}
</script>
