<template>
  <div class="cheat-sheet">
    <div class="page-component"></div>
    <div class="container-sm page-component">
      <h1>CHEAT SHEET</h1>
      <h2>This is a sub title.</h2>
      <p>
        Lorem Ipsum dolir sit ame quo cosa. Lorem Ipsum dolir sit ame quo cosa. Lorem Ipsum dolir sit ame quo cosa. Lorem Ipsum dolir sit ame quo cosa.
        Lorem Ipsum dolir sit ame quo cosa. Lorem Ipsum dolir sit ame quo cosa. Lorem Ipsum dolir sit ame quo cosa. Lorem Ipsum dolir sit ame quo cosa.
        Lorem Ipsum dolir sit ame quo cosa. Lorem Ipsum dolir sit ame quo cosa. Lorem Ipsum dolir sit ame quo cosa. Lorem Ipsum dolir sit ame quo cosa.
      </p>
    </div>
    <div class="container-sm page-component">
      <h1>COLORS</h1>
      <div class="color-card color-card-cyan">cyan</div>
      <div class="color-card color-card-blue">blue</div>
      <div class="color-card color-card-purple">purple</div>
    </div>
    <div class="container-sm page-component">
      <h1>CHAT TITLE</h1>
      <section-title :messages="sectionTitleMessages"/>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/elements/SectionTitle.vue'

export default {
  name: 'CheatSheet',
  components: { SectionTitle },
  data () {
    return {
      sectionTitleMessages: [
        {
          side: 'right',
          content: 'Is this a title?'
        },
        {
          side: 'left',
          content: 'Hi There!'
        },
        {
          side: 'left',
          content: 'Yeaah this is a title, cool eh?'
        }
      ]
    }
  }
}
</script>
