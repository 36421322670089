<template>
  <div class="home">
    <div class="site-wrapper__left">
      <sidenav :nav-content="navContent"/>
    </div>
    <div class="site-wrapper__content">
      <!-- workaround header side nav scroll: scroll to absolut top -->
      <div class="js-nav-indicator" data-index="0"></div>
      <Header page-index="0"/>
      <slogan page-index="1"/>
      <research-list page-index="2" :show-section-title="true"/>
      <projects page-index="3"
                :projects="projectsData"
                :show-section-title="true" />
      <experience page-index="4"/>
      <!-- Ideas: 
        Wer bin ich?
        Kurzer Steckbrif mit Bild von Kopf dass sich 360 dreht beim Scrollen
      -->
      <contact page-index="4"/>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Slogan from '@/components/Slogan.vue'
import Rellax from 'rellax'
import Projects from '@/components/Projects.vue'
import Experience from '@/components/Experience.vue'
import Contact from '../components/Contact.vue'
import Sidenav from '@/elements/Sidenav.vue'
import ResearchList from '../components/ResearchList.vue'

export default {
  name: 'Home',
  components: { Header, Slogan, Projects, Experience, Contact, Sidenav, ResearchList },

  mounted () {
    new Rellax('.rellax')
  },

  data () {
    return {
      projectsData: [
        {
          image: '/images/projects/SRF-Medienportal-logo.svg',
          contentHTML: `<h1>SRF Medienportal</h1>
          <p class="projects__slider-meta">worked as <span>Full-Stack Developer</span> for <span>clavis IT AG</span></p>
          <p>The media portal of the Swiss national broadcaster SRF is the contact point for press regarding new TV programs, audience figures, company news, chronicles and much more.</p>
          <a class="btn" target="_blank" href="https://medien.srf.ch/">medien.srf.ch</a>
          `
        },
        {
          image: '/images/projects/Keystone-SDA-logo.png',
          contentHTML: `<h1>Keystone-SDA</h1>
          <p class="projects__slider-meta">worked as <span>Front-End Developer</span> for <span>clavis IT AG</span></p>
          <p>Keystone-SDA is one of the largest news agencies in Switzerland. The company website developed by clavis IT presents the agency's services, formats and news, among other things.</p>
          <a class="btn" target="_blank" href="https://www.keystone-sda.ch/">keystone-sda.ch</a>
          `
        },
        {
          image: '/images/projects/Kriens-logo.png',
          contentHTML: `<h1>Kriens Tourismus</h1>
          <p class="projects__slider-meta">worked as <span>Project Manager</span> and <span>Lead-Developer</span></p>
          <p>Kriens Tourism is the official tourism website of the town of Kriens, which borders Lucerne, and offers tourists and visitors an overview of the town's sights, restaurants, shopping facilities and activities.</p>
          <a class="btn" target="_blank" href="https://kriens-tourismus.ch/#/home">kriens-tourismus.ch</a>
        `
        },
        {
          image: '/images/projects/AE-Photography-logo.svg',
          contentHTML: `<h1>AE Photography</h1>
          <p class="projects__slider-meta">private project</p> 
          <p>The Alexander Ehrenhöfer Photography site is a private project and serves on the one hand as a portfolio for photos, but above all as my own exercise and playground for my web programming.</p>
          <a class="btn" target="_blank" href="https://www.photography.alexander-ehrenhoefer.com/#/">go to page</a>
          `
        }
      ],
      navContent: [
        {
          pageIndex: 0,
          title: 'Hello'
        },
        {
          pageIndex: 1,
          title: 'Intro'
        },
        {
          pageIndex: 2,
          title: 'Research'
        },
        {
          pageIndex: 3,
          title: 'References'
        },
        {
          pageIndex: 4,
          title: 'Experience'
        },
        {
          pageIndex: 5,
          title: 'Contact'
        }
      ]
    }
  }

}
</script>
