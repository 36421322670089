<template>
  <div class="showcase-bachelorthesis">
    <div class="site-wrapper__content no-padding-sm">
      <div class="container page-component">
        <h1>Bachelorarbeit</h1>
        <h3 class="color-blue">Machine Learning basierte Konstruktion von 3D Modellen aus  2D Bildern zur Optimierung der Gesichtserkennung</h3>
        <p>Abstract</p>
        <p>
          Die Thematik der Gesichtserkennung gilt als eine der wichtigsten im Bereich der Computer 
          Vision. Mit den bisher gängigen 2D Analyse Verfahren können unter den meisten Umständen
          bereits sehr gute Ergebnisse erzielt werden. Doch die 2D basierte Gesichtserkennung stößt 
          bei Herausforderungen wie starken Abweichungen der Pose, Mimik oder Beleuchtung des zu 
          identifizierenden Gesichtes an ihre Grenzen.<br><br>Neue Machine Learning basierte Verfahren 
          ermöglichen die 3D Gesichtsmodell Rekonstruktion anhand eines einzigen einfachen 2D 
          Gesichtsbildes (d.h. ohne jegliche Art von 3D Scans).Aus einfachen 2D Bildern werden somit
          detaillierte 3D Modelle generiert, welche dann für Gesichtserkennungsverfahren angewendet 
          werden können. Im Rahmen dieser Arbeit werden die Möglichkeiten der 3D basierten 
          Gesichtserkennungsverfahren untersucht und umfassende Tests in unterschiedlichen 
          Szenarien mit mehreren großen Bild Datensätzen durchgeführt.<br><br>Das Ziel ist es, die 
          Zuverlässigkeit klassischer 2D Gesichtserkennungsverfahren mit den neuen 3D basierten 
          Ansätzen zu vergleichen. Dafür werden unterschiedliche Ansätze der 3D Gesichtserkennung 
          behandelt und auch eigens programmiert, da noch keine öffentlich zugängliche 
          Implementation der verwendeten Verfahren existiert.<br><br>Die Tests sollen durch eine 
          automatisierte und flexible Testing Pipeline umgesetzt werden, welche im Rahmen dieser 
          Arbeit konzipiert und aufgebaut wird. Schlussendlich sollen die Testergebnisse das Potenzial 
          der 3D Gesichtserkennung zeigen und untersuchen ob die Hürden der 2D Gesichtserkennung 
          damit überwunden werden können.
        </p>
        <br><br>
        <a class="btn" target="_blank" href="/cloud/BA_Alexander_Ehrenhoefer_2024.pdf">Zum PDF</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ShowcaseBachelorThesis',
  mounted () {
    document.body.classList.remove('loading')
  }
}
</script>
