<template>
  <div class="page-component" @mousemove="mouseMove">
    <section-title v-if="showSectionTitle" :messages="researchListTitle" />
    <div class="js-nav-indicator" v-view="(e) => updateNavIndex(e, pageIndex)" :data-index="pageIndex"></div>
    <div class="container researchlist__container">
      <div class="researchlist__item featured" title="read more" @click="$router.push('/research/bachelor-thesis')">
        <div class="researchlist__item-image">
          <!--<img src="/images/research/alex-3dmm.png"/>-->
          <research-thesis-scene />
        </div>
        <div class="researchlist__item-text">
          <p class="meta color-red">Bachelor Thesis<span class="color-gray"></span></p>
          <h3>Machine learning based reconstruction of 3D models from 2D images to optimize facial recognition</h3>
        </div>
      </div>
      <a class="researchlist__item" title="show me" href="/playground/temple" target="_blank">
        <div class="researchlist__item-image">
          <img src="/images/research/temple_t.png"/>
        </div>
        <div class="researchlist__item-text">
          <p class="meta color-cyan">3D Web Experiment</p>
          <h3>Testing High Performance realistic Light and Shadow Mapping with Three.js and Blender</h3>
        </div>
      </a>
      <!-- TODO: Temple -->
      <!-- TODO: CNN Movie Poster -->
      <!-- TODO: ISD -->
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/elements/SectionTitle.vue'
import { EventBus } from '@/js/EventBus.js'
import ResearchThesisScene from '../elements/ResearchThesisScene.vue'

export default {
  components: { SectionTitle, ResearchThesisScene },
  name: 'ResearchList',
  props: ['pageIndex', 'projects', 'showSectionTitle'],
  data () {
    return {
      researchListTitle: [
        {
          side: 'right',
          content: 'What are you researching lately?'
        },
        {
          side: 'left',
          content: 'Here are some of my latest research projects'
        }
      ]
    }
  },
  created () {},
  methods: {
    updateNavIndex (e, index) {
      EventBus.$emit('update-nav-index', index)
    },

    mouseMove (e) {
      EventBus.$emit('research-mouse-move', e)
    }
  }
}
</script>
