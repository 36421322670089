<template>
  <div class="header page-component">
    <div class="header__text">
      <h1 class="xl">ALEXANDER EHRENHÖFER</h1>
      <h3>Developer</h3>
      <div class="js-nav-indicator" v-view="(e) => updateNavIndex(e, pageIndex)"></div>
    </div>
    <div class="header__animation-wrapper">
      <div class="header__animation-stripe-container">
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
        <div class="header__animation-stripe"></div>
      </div>
    </div>
    <scroll-indicator v-if="showScrollIndicator == 1"/>
  </div>
</template>

<script>
import { EventBus } from '@/js/EventBus.js'
import gsap from 'gsap'
import ScrollIndicator from '@/elements/ScrollIndicator.vue'

export default {
  components: { ScrollIndicator },
  name: 'Header',
  props: ['pageIndex'],

  data () {
    return {
      timeline: null,
      animMaxProgress: 0.5,
      isLoadingDone: false,
      showScrollIndicator: -1
    }
  },

  mounted () {
    EventBus.$on('projects-loaded', () => this.endLoading())

    this.timeline = gsap.timeline().from('.header__animation-stripe', {
      borderColor: '#3f4042',
    }).to('.header__animation-stripe', {
      duration: 0.2,
      scaleY: 1.1,
      borderColor: '#575df0',
      ease: 'circ',
      stagger: {
        each: 0.02,
        from: 'start',
      }
    }).to('.header__animation-stripe', {
      duration: 0.3,
      scaleY: 0.8,
      ease: 'circ',
      borderColor: '#3f4042',
      stagger: {
        amount: 0.3,
        from: 'start',
      }
    }).fromTo('.header__animation-stripe', {
        borderColor: '#9ad5f9',
      },{
        duration: 0.2,
        borderColor: '#3f4042',
        stagger: {
          amount: 0.3,
          from: 'start',
        }
      }
    )

    gsap.ticker.add(() => {
      if (this.timeline.progress() >= this.animMaxProgress) {
        this.timeline.timeScale(0.2)
      } else {
        this.timeline.timeScale(1)
      }

      if (this.isLoadingDone && this.timeline.progress() >= 1) {
        document.body.classList.remove('loading')
        this.timeline.to('.header__animation-stripe', {
          duration: 3,
          scaleY: 1,
          ease: 'circ.in',
          borderColor: '#9ad5f9',
          stagger: {
            each: 3 / 60,
            from: 'center',
            repeat: -1,
            yoyo: true
          }
        })

        //Workaround because of v-view, move navigation to start
        EventBus.$emit('update-nav-index', this.pageIndex)
      }
    })
  },

  methods: {
    endLoading () {
      this.animMaxProgress = 1
      this.isLoadingDone = true

      setTimeout(() => 
        {
          if (this.showScrollIndicator == -1) {
            this.showScrollIndicator = 1
          }
        },
        10000
      )
      document.addEventListener("scroll", () => {
        this.showScrollIndicator = 0
      }, {once: true})
    },
    updateNavIndex (e, index) {
      EventBus.$emit('update-nav-index', index)
    }
  }
}
</script>
