<template>
  <div class="cloud-download">
    <span class="nav-span"><a href="/">home</a> > download</span>
    <div class="no-padding-sm full-page-center">
      <a class="feature-card glow" :href="'/cloud/' + $route.params.filename" download>
          <simple-svg :src="`/svg/download.svg`" />
          <p>{{ $route.params.filename }}</p>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CloudDownload',
  mounted () {
    document.body.classList.remove('loading')
  }
}
</script>
